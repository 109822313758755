@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


html,
body {
  position: relative;
  overflow: hidden;
  height: 100%;
}


body {
  font-family: 'Ubuntu', sans-serif;
  color: #0a0a0a;
  margin: 0 auto;
}

* {
  font-family: 'Ubuntu', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1F1F1F;
}

#root {
  @apply w-full md:max-w-[400px] my-0 mx-auto bg-gray-100 bg-opacity-60 h-full flex flex-1 items-start
}


.form-control {
  @apply flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-[#0e171b] focus:outline-0 focus:ring-0 border border-[#d0dfe7] bg-white focus:border-[#d0dfe7] h-14 placeholder:text-[#4e7f97] p-[15px] text-base font-normal leading-normal
}

.form-control2 {
  @apply flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl focus:outline-0 focus:ring-0 border border-[#d0dfe7] bg-white focus:border-[#d0dfe7] placeholder:text-[#4e7f97] p-[8px] text-sm text-gray-800 font-normal leading-normal
}


.answer-wrapper  {
  @apply relative
}
.answer-wrapper div  {
  @apply w-full overflow-x-auto text-sm
}
.answer-wrapper table {
  @apply w-full border-spacing-2 
}
.answer-wrapper table thead {
  @apply border-separate border-t border-b
}
.answer-wrapper table thead tr th {
  @apply px-2 py-3 text-xs
}
.answer-wrapper table tbody tr td {
  @apply px-2 pb-2 pt-1 text-xs
}
.answer-wrapper ul {
  @apply list-inside list-disc
}

.answer-wrapper h3,
.answer-wrapper h4 {
  @apply font-semibold
}

textarea { resize: none; }

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
}

a {
  @apply text-blue-400 text-sm
}


/* Fade In Animation */
.fade-in {
  animation: fadeIn 0.5s forwards;
}

/* Fade Out Animation */
.fade-out {
  animation: fadeOut 0.5s forwards;
}

/* Keyframes for Fade In */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframes for Fade Out */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
